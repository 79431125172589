<template>
  <div>
    <div class="container">
      <el-container>
        <transition name="el-zoom-in-center">
          <i
            v-show="!showTree"
            class="el-icon-s-unfold"
            @click="showTree=true"
            style="float:left;cursor: pointer;color:#333;font-size: 24px;margin-right: 16px;"
          ></i>
        </transition>
        <transition name="el-zoom-in-top">
          <el-aside width="240px" v-show="showTree">
            <i
              class="el-icon-s-fold"
              @click="showTree=false"
              style="float:right;cursor: pointer;color:#333;font-size: 30px;margin-right: 8px;"
            ></i>
            <type-tree typeKind="EmployeeType" @currentTypeArray="currentTypeArray"></type-tree>
          </el-aside>
        </transition>

        <el-main>
          <el-table :loading="loading" :data="data" stripe border @row-dblclick="editType">
            <el-table-column label="序号" type="index" width="80" show-overflow-tooltip></el-table-column>
            <el-table-column label="排序" prop="code" show-overflow-tooltip></el-table-column>
            <el-table-column label="工时费" prop="price" show-overflow-tooltip></el-table-column>
            <el-table-column label="名称" prop="name" show-overflow-tooltip></el-table-column>
          </el-table>
        </el-main>
      </el-container>
    </div>
    <div>
      <edit-type typeKind="EmployeeType" :item="employeeTypeItem" @success="getData"></edit-type>
    </div>
  </div>
</template>

<script>
import TypeTree from "@/components/BaseType/TypeTree";
import EditType from "@/components/BaseType/TypeItem";
export default {
  components: {
    TypeTree,
    EditType
  },
  data() {
    return {
      showTree: true,
      data: [],
      filter: [],
      employeeTypeItem: {},
      loading: false
    };
  },
  watch: {
    "$store.state.baseinfo.EmployeeType": {
      handler: function(v) {
        this.getData();
      }
    }
  },
  activated() {
    this.getData();
  },
  methods: {
    getData() {
      let filter = {
        typeArray: this.filter
      };
      this.loading = true;
      this.$get("Sys/GetAllEmployeeType", filter)
        .then(r => {
          this.data = r;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    currentTypeArray(array) {
      this.filter = array;
      this.getData();
    },
    editType(row) {
      this.employeeTypeItem = {
        id: row.id
      };
    }
  }
};
</script>

<style>
</style>
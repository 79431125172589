<template>
  <div>
    <el-button-group style="margin-bottom:8px;">
      <el-tooltip class="item" effect="dark" content="添加" placement="top">
        <el-button size="mini" type="primary" icon="el-icon-plus" @click="addItem"></el-button>
      </el-tooltip>

      <el-tooltip class="item" effect="dark" content="修改" placement="top">
        <el-button size="mini" type="warning" icon="el-icon-edit" @click="editItem"></el-button>
      </el-tooltip>

      <el-tooltip class="item" effect="dark" content="删除" placement="top">
        <el-button size="mini" type="danger" icon="el-icon-delete" @click="deleteItem"></el-button>
      </el-tooltip>

      <el-tooltip class="item" effect="dark" content="清除筛选" placement="top">
        <el-button size="mini" type="info" icon="el-icon-finished" @click="clearFilter"></el-button>
      </el-tooltip>
    </el-button-group>
    <el-tree
      ref="tree"
      :data="treeData"
      node-key="id"
      highlight-current
      :expand-on-click-node="false"
      @node-click="handleTreeClick"
    >
      <span slot-scope="{ node,data }">
        <svg class="icon" aria-hidden="true" v-if="data.children">
          <use xlink:href="#iconfile" />
        </svg>
        <svg class="icon" aria-hidden="true" v-else>
          <use xlink:href="#icontext" />
        </svg>
        <span>{{ data.name }}</span>
      </span>
    </el-tree>
    <div>
      <add-edit :item="item" @success="clearFilter" :typeKind="typeKind"></add-edit>
    </div>
  </div>
</template>

<script>
import tree from "@/common/tree.js";
import AddEdit from "@/components/BaseType/TypeItem";
export default {
  components: {
    AddEdit
  },
  //GoodsType,EmployeeType,ExpenseType,ClientType,SupplierType
  props: ["typeKind"],
  data() {
    return {
      item: {}
    };
  },
  computed: {
    treeData() {
      let array = this.$store.state.baseinfo[this.typeKind];
      return tree.arrayToTree(array, null);
    }
  },
  methods: {
    addItem() {
      let node = this.$refs["tree"].getCurrentNode();
      if (node) {
        this.item = {
          parentId: node.id
        };
      } else {
        this.item = {};
      }
    },
    editItem() {
      let id = this.$refs["tree"].getCurrentKey();
      if (id == null) {
        this.$message.error("请选择需要编辑的类别");
        return;
      }
      this.item = {
        id
      };
    },
    deleteItem() {
      let node = this.$refs["tree"].getCurrentNode();
      if (node == null) {
        this.$message.error("请选择将要删除的类别");
        return;
      }
      this.$confirm(
        "将删除【" + node.name + "】及下级所有类别, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      )
        .then(() => {
          let loading = this.$loading({
            lock: true,
            text: "加载中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          var array = tree.getCildrenIdArray(node);
          this.$post("Sys/DeleteType", {
            typeKind: this.typeKind,
            ids: array
          })
            .then(r => {
              this.clearFilter();
              this.$message.success("操作成功！");
              array.forEach(id => {
                this.$store.commit("removeBaseInfo", {
                  info: this.typeKind,
                  id: id
                });
              });
            })
            .finally(() => {
              loading.close();
            });
        })
        .catch(() => {});
    },
    clearFilter() {
      this.$refs["tree"].setCurrentKey(null);
      this.$emit("currentTypeArray", []);
    },
    handleTreeClick(data) {
      var array = tree.getCildrenIdArray(data);
      this.$emit("currentTypeArray", array);
    }
  }
};
</script>

<style>
</style>